// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/login.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/login.tsx");
  import.meta.hot.lastModified = "1723735562938.8052";
}
// REMIX HMR END

import { json, redirect } from "@remix-run/node";
import { Form, Link, useActionData, useSearchParams } from "@remix-run/react";
import { useEffect, useRef } from "react";
import { verifyLogin } from "~/models/user.server";
import { createUserSession, getUserId } from "~/session.server";
import { safeRedirect, validateEmail } from "~/utils";
export const loader = async ({
  request
}) => {
  const userId = await getUserId(request);
  if (userId) return redirect("/");
  return json({});
};
export const action = async ({
  request
}) => {
  const formData = await request.formData();
  const userOrEmail = formData.get("userOrEmail");
  const password = formData.get("password");
  const redirectTo = safeRedirect(formData.get("redirectTo"), "/");
  const remember = formData.get("remember");
  if (typeof userOrEmail !== "string" || userOrEmail.length === 0) {
    return json({
      errors: {
        email: "Username or email is required",
        password: null
      }
    }, {
      status: 400
    });
  }
  if (userOrEmail.includes("@")) {
    if (!validateEmail(userOrEmail)) {
      return json({
        errors: {
          email: "Email is invalid",
          password: null
        }
      }, {
        status: 400
      });
    }
  }
  if (typeof password !== "string" || password.length === 0) {
    return json({
      errors: {
        email: null,
        password: "Password is required"
      }
    }, {
      status: 400
    });
  }
  if (password.length < 8) {
    return json({
      errors: {
        email: null,
        password: "Password is too short"
      }
    }, {
      status: 400
    });
  }
  const user = await verifyLogin(userOrEmail, password);
  if (!user) {
    return json({
      errors: {
        email: "Invalid email or password",
        password: null
      }
    }, {
      status: 400
    });
  }
  return createUserSession({
    redirectTo,
    remember: remember === "on" ? true : false,
    request,
    userId: user.pk
  });
};
export const meta = () => [{
  title: "Login"
}];
export default function LoginPage() {
  _s();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirectTo") || "/";
  const actionData = useActionData();
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  useEffect(() => {
    if (actionData?.errors?.email) {
      emailRef.current?.focus();
    } else if (actionData?.errors?.password) {
      passwordRef.current?.focus();
    }
  }, [actionData]);
  return <div className="flex min-h-full flex-col justify-center">
      <div className="relative mx-auto w-full max-w-md px-8">
        <Form method="post" className="space-y-6">
          <div>
            <label htmlFor="userOrEmail" className="block text-sm font-medium text-gray-700">
              Username or Email
            </label>
            <div className="mt-1">
              <input ref={emailRef} id="userOrEmail" required
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={true} name="userOrEmail" autoComplete="email" aria-invalid={actionData?.errors?.email ? true : undefined} aria-describedby="email-error" className="w-full rounded border border-gray-500 px-2 py-1 text-lg" />
              {actionData?.errors?.email ? <div className="pt-1 text-red-700" id="email-error">
                  {actionData.errors.email}
                </div> : null}
            </div>
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <div className="mt-1">
              <input id="password" ref={passwordRef} name="password" type="password" autoComplete="current-password" aria-invalid={actionData?.errors?.password ? true : undefined} aria-describedby="password-error" className="w-full rounded border border-gray-500 px-2 py-1 text-lg" />
              {actionData?.errors?.password ? <div className="pt-1 text-red-700" id="password-error">
                  {actionData.errors.password}
                </div> : null}
            </div>
            <div className="text-center text-sm text-secondary">
              Forgot password?{" "}
              <Link className="text-blue-500 underline" to={{
              pathname: "/forgot-password",
              search: searchParams.toString()
            }}>
                Click Here
              </Link>
            </div>
          </div>

          <input type="hidden" name="redirectTo" value={redirectTo} />
          <button type="submit" className="w-full btn btn-neutral">
            Log in
          </button>
          <div className="flex flex-col items-center justify-between">
            <div className="flex items-center">
              <input id="remember" name="remember" type="checkbox" className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500" />
              <label htmlFor="remember" className="ml-2 block text-sm text-secondary">
                Remember me
              </label>
            </div>
            <div className="text-center text-sm text-secondary">
              Don&apos;t have an account?{" "}
              <Link className="text-blue-500 underline" to={{
              pathname: "/join",
              search: searchParams.toString()
            }}>
                Sign up
              </Link>
            </div>
          </div>
        </Form>
      </div>
    </div>;
}
_s(LoginPage, "RidIhLwFrqAsyRbtfUp5EYY5fes=", false, function () {
  return [useSearchParams, useActionData];
});
_c = LoginPage;
var _c;
$RefreshReg$(_c, "LoginPage");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;